import { Box, Divider, Grid, Typography } from '@mui/material';
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ChartDataInterface } from 'modules/charts/hooks/use-fetch-chart-data.hook';
import { usePerformanceChartStyles } from 'modules/charts/styles';
import { convertNumberToFormat, getCurrencySymbol } from 'modules/common/utils';
import { FunctionComponent, useState } from 'react';
import { Line } from 'react-chartjs-2';

interface IProps {
  chartData: ChartDataInterface;
}
interface PriceChartProp {
  months: string[];
  prices: {
    price?: number;
    repeated?: boolean;
    currency?: string;
  }[];
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ChartDataLabels,
);

const ChartWrapper = (props: PriceChartProp) => {
  const { months, prices } = props;
  const classes = usePerformanceChartStyles();
  const hidePointsArray = prices.map((x) => {
    if (!x.repeated && x.price) {
      //If item is to be displayed we change it's pointRadius to 4
      return 3;
    } else {
      return 0;
    }
  });

  const [priceCurrencyCode] = useState('EUR');

  const label = (context) => {
    const value = context?.parsed?.y;
    return `${convertNumberToFormat(value, priceCurrencyCode, true)}`;
  };

  const chartData = {
    labels: months,
    datasets: [
      {
        data: prices.map((x) => {
          if (x.price) {
            return x.price;
          } else {
            return null;
          }
        }),
        borderColor: 'rgba(0, 0, 0, 0.1)',
        borderWidth: 1,
        backgroundColor: (context) => {
          const bgColor = [
            'rgba(116, 191, 180, 0.5)',
            'rgba(116, 191, 180, 0.3)',
            'rgba(116, 191, 180, 0.2)',
            'rgba(116, 191, 180, 0.05)',
          ];
          if (!context.chart.chartArea) {
            return;
          }

          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0, bgColor[0]);
          gradientBg.addColorStop(0.3, bgColor[1]);
          gradientBg.addColorStop(0.6, bgColor[2]);
          gradientBg.addColorStop(1, bgColor[3]);
          return gradientBg;
        },
        pointBackgroundColor: 'rgba(116, 191, 180,1)',
        pointRadius: hidePointsArray,
        pointHoverRadius: hidePointsArray,
        pointHoverBorderWidth: hidePointsArray,
        pointHitRadius: hidePointsArray,
        pointBorderColor: 'rgba(116, 191, 180,1)',
      },
    ],
  };

  const options = {
    fill: true,
    maintainAspectRatio: false,
    aspectRatio: 2,
    tension: 0.1,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        padding: 2,
        offset: -1,
        color: '#000',
        anchor: 'top',
        align: 'top',
        formatter: (value, i) => {
          if (props?.prices[i.dataIndex].repeated) {
            return '';
          } else {
            return convertNumberToFormat(value, priceCurrencyCode, false);
          }
        }, // Format the data labels as needed
      },
      tooltip: {
        enabled: false,
        // callbacks: {
        //   label,
        // },
        // backgroundColor: '#65ada3',
        // padding: 8,
      },
    },
    scale: {
      ticks: {
        precision: 0, // Adjust the step size to control the padding between ticks
      },
    },
    scales: {
      y: {
        grid: {
          color: '#e5eff1',
          display: true,
          drawBorder: false,
          borderDash: [5, 5],
        },
        display: true,
        beginAtZero: true,
        suggestedMax: Math.max(...prices.map((x) => x.price)) + 10, // Adjust the max value to provide padding at the top
      },
      x: {
        grid: {
          display: false,
          drawBorder: true,
        },
      },
    },
    responsive: true,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (
    <div className={classes.lineChart}>
      <div className={classes.lineUnit}>{getCurrencySymbol(props?.prices?.[0]?.currency || 'EUR')}</div>
      <Line options={options as unknown} data={chartData as any} />
    </div>
  );
};

export const PriceChart: FunctionComponent<IProps> = (props: IProps) => {
  const { chartData } = props;
  const { yAxisPriceChart, xAxis } = chartData;

  return (
    <div>
      <Box width={'100%'} padding={'18px'}>
        <Typography
          color="black"
          component={'div'}
          variant='semiBold'
          sx={{
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '133.4%',
          }}
        >
          Average Price per Unit (Ordered per Month)
        </Typography>
      </Box>
      <Divider />
      <Grid container direction="row" justifyContent="center" alignItems="center" margin={0}>
        <Grid item xs={12}>
          <Box>
            <ChartWrapper months={xAxis} prices={yAxisPriceChart} />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
