import { useAsyncOperation } from 'modules/common/hooks';
import { UseAsyncHookInterface } from 'modules/common/hooks/use-async-operation.hook';
import { ContractTypeEnum } from 'modules/contract-details/enums/contract-type.enum';
import { useContractDetails } from 'modules/contract-details/hooks';
import { ContractDetailsInterface } from 'modules/contract-details/interfaces';

export interface FetchContractsDetailsArgsInterface {
  id: string,
  type: ContractTypeEnum
}

export const useFetchContractDetails = (): UseAsyncHookInterface<FetchContractsDetailsArgsInterface, ContractDetailsInterface> => {
  const { fetchContractDetails } = useContractDetails()
  const result = useAsyncOperation({
    callback: fetchContractDetails
  })

  return result
}
