import { Box, Grid, Paper, Stack, Typography } from '@mui/material'
import reduce from 'lodash/reduce'
import { InvoicesStatusColors } from 'modules/invoices/enums/invoices-status-colors.enum'
import { useInvoicesStatusCount } from 'modules/invoices/hooks/use-invoices-status-count.hook'
import { InvoicesFetchStatusCountArgsInterface } from 'modules/invoices/interfaces/invoices-fetch-status-count-args.interface'
import { InvoicesStatusCountInterface } from 'modules/invoices/interfaces/invoices-status-count.interface'
import { useEffect, useState } from 'react'

const SmallCircle = ({ color }: { color: string }) => <Box sx={{
  height: "0.8rem",
  width: "0.8rem",
  backgroundColor: color,
  borderRadius: "100%",
  marginTop: 0.5
}} ></Box>

const getStatusColor = (status) => {
  switch (status) {
    case "Cleared":
      return InvoicesStatusColors.CLEARED
    case "Outstanding":
      return InvoicesStatusColors.OUTSTANDING
    case "Overdue":
      return InvoicesStatusColors.OVERDUE
    default:
      return "blue"
  }
}

const getStatusBarsBorderRadius = (statusArray: InvoicesStatusCountInterface[]) => {
  const statusesWithTotalCount = statusArray.filter(({ totalCount }) => totalCount > 0);
  const firstStatusWithTotalCount = statusesWithTotalCount[0];
  const lastStatusWithTotalCount = statusesWithTotalCount[statusesWithTotalCount.length - 1];
  const isOneStatusBar = (
    statusesWithTotalCount.length === 1
  );
  const isNoStatusBar = statusesWithTotalCount.length === 0;

  const barsBorderRadius = statusArray.map((value) => {
    if (isNoStatusBar || (isOneStatusBar && !value.totalCount)) {
      return {}
    }

    if (isOneStatusBar && value.totalCount) {
      return {
        borderRadius: '999px'
      }
    }

    if (value.status === firstStatusWithTotalCount?.status) {
      return {
        borderRadius: '999px 0 0 999px' // border radius left
      }
    } else if (value.status === lastStatusWithTotalCount?.status) {
      return {
        borderRadius: '0 999px 999px 0' // border radius right
      }
    } 
      return {}
  })
  return barsBorderRadius;
}

const statusesDefaultCollection: InvoicesStatusCountInterface[] = [
  { status: 'Cleared', totalCount: 0 },
  { status: 'Outstanding', totalCount: 0 },
  { status: 'Overdue', totalCount: 0 }];

export const InvoicesStatusPercentageBar = ({ invoicesTableFilter }: { invoicesTableFilter: Partial<InvoicesFetchStatusCountArgsInterface>['filter'] }): React.ReactElement => {
  const { data, isLoading, total } = useInvoicesStatusCount({ filter: invoicesTableFilter })
  const [formattedStatusData, setFormattedStatusData] = useState(statusesDefaultCollection);

  const formatStatusDataHandler = (statusData: InvoicesStatusCountInterface[]): InvoicesStatusCountInterface[] => {
    const formattedStatusCollection = reduce(statusData, (statusesCollection, value) => {
      const currentStatusCount = statusesCollection?.find(({ status }) => status === value?.status)?.totalCount || 0;
      const filteredCollection = statusesCollection?.filter((item) => item?.status !== value?.status)
      return [...filteredCollection, { status: value?.status, totalCount: (value?.totalCount + currentStatusCount) }]
    }, statusesDefaultCollection)

    const statusOrder = { "Cleared": 0, "Outstanding": 1, "Overdue": 2 };
    return formattedStatusCollection.sort((a, b) => statusOrder[a.status] - statusOrder[b.status]);
  }
  const statusBarsBorderRadiusArray = getStatusBarsBorderRadius(formattedStatusData);

  useEffect(() => {
    const formattedStatuses = formatStatusDataHandler(data);
    setFormattedStatusData(formattedStatuses);
  }, [data]);

  return (
    <Paper elevation={0} sx={{ borderRadius: 0 }}>
      {
        !isLoading ?
          <Grid container p={2} direction="column" xs={12} gap={1}>
            <Typography fontSize={17} color={"black"} fontWeight={800}>Total</Typography>
            <Stack p={0.2} direction={"row"} alignContent={"center"}>
              {formattedStatusData?.map((statusItem, index) =>
                <Box key={index} height={10} width={`${statusItem?.totalCount * 100 / total}%`}
                  sx={{
                    backgroundColor: getStatusColor(statusItem?.status),
                    ...statusBarsBorderRadiusArray[index],
                  }}></Box>
              )}
            </Stack>
            <Stack direction={"row"} justifyItems={"center"} alignContent={"center"} justifyContent={"space-between"}>
              <Grid container direction={"row"} gap={3} >
                {formattedStatusData?.map((statusItem, index) =>
                  <Stack direction={"row"} spacing={1} key={`circle-${index}`}>
                    <SmallCircle color={getStatusColor(statusItem?.status)} />
                    <Typography key={index} fontSize={14} lineHeight={'166%'} marginRight={1} color={"black"} fontWeight={800} >{`${statusItem?.status} (${statusItem?.totalCount})`}</Typography>
                  </Stack>

                )}
              </Grid>
              <Typography fontSize={14} color={"black"} noWrap lineHeight={'166%'} fontWeight={800} sx={{ minWidth: "max-content" }}>Total: {total}</Typography>
            </Stack>
          </Grid>
          :
          null
      }
    </Paper>
  )
} 