// eslint-disable-next-line @roq/no-invalid-resource-location
import React, { useEffect, useState } from "react";
import { useProgressLineStatusStyles } from 'views/contract-details/partials/contract-details-status/progress-line-status.styles';


interface VisualPart {
  percentage: string;
  color: string;
}

interface ProgressLineProps {
  backgroundColor?: string;
  visualParts?: VisualPart[];
  borderRadius?: string;
}

export const ProgressLineStatusPartial: React.FC<ProgressLineProps> = ({
  backgroundColor = "#e5e5e5",
  visualParts = [
    {
      percentage: "0%",
      color: "white"
    }
  ],
  borderRadius = "2px"
}) => {
  const [widths, setWidths] = useState<string[]>(
    visualParts.map(() => "0%")
  );
  const classes = useProgressLineStatusStyles();

  useEffect(() => {
    requestAnimationFrame(() => {
      setWidths(
        visualParts?.map(item => item.percentage)
      );
    });
  }, [visualParts]);

  return (
    <>
      <div
        className={classes.progressVisualFull}
        style={{
          backgroundColor,
          borderRadius
        }}
        
      >
        {visualParts?.map((item, index) => (
          <div
            key={index}
            style={{
              width: widths[index],
              backgroundColor: item.color,
              borderRadius
            }}
            className={classes.progressVisualPart}
          />
        ))}
      </div>
    </>
  );
};

