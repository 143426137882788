export enum OrderSortEnum {
  ID = 'ID',
  CUST_CONTRACT_REF = 'CUST_CONTRACT_REF',
  CONTRACT_ID = 'CONTRACT_ID',
  DELIVERY_DATE = 'DELIVERY_DATE',
  ORDER_CREATED_AT = 'ORDER_CREATED_AT',
  FIRST_PRODUCT_NAME = 'FIRST_PRODUCT_NAME',
  ORDER_SHIPPING_ADDRESS = 'ORDER_SHIPPING_ADDRESS',
  STATUS = 'STATUS',
  DELIVERY_COUNT = 'DELIVERY_COUNT',
  ORDER_QUANTITY = 'ORDER_QUANTITY'
}
