import { Theme } from '@mui/material';
import { roqMakeStyles } from 'modules/common/utils';
import { chartBarsThemes } from 'views/contract-details/partials/contract-details-status/chart-bars-themes.partial';

export const usePerformanceChartStyles = roqMakeStyles((theme: Theme) => ({
  averageQuantityDiv: {
    backgroundColor: chartBarsThemes.lightGreen,
    paddingLeft: '4px',
    paddingRight: '4px',
    display: 'inline-block',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '166%',
    letterSpacing: '0.17px',
  },
  barChart: {
    position: 'relative',
  },
  lineChart: {
    position: 'relative',
    paddingTop: '55px',
    padding: '18px',
    height: '440px',
  },

  barUnit: {
    position: 'absolute',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '157%',
    top: '8px',
    left: '5px',
  },
  lineUnit: {
    position: 'absolute',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '157%',
    marginLeft: '15px',
    top: '18px',
    left: '14px',
  },
  boxLayout: {
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    padding: theme.spacing(3),
    marginTop: '10px',
    borderRadius: '2px',
  },
  totalQuantityProgress: {
    width: '90%',
    height: '8px',
    '&.MuiLinearProgress-determinate': {
      backgroundColor: '#EBF4FB',
      border: '8px #3890DB',
      borderRadius: '8px',
    },
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#3890DB',
    },
  },
  orderQuantityDiv: {
    color: '#666666',
  },
}));
