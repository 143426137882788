import { Box, Box as MuiBox, Container, Divider, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip as ChartjsTooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import max from 'lodash/max';
import { ChartDataInterface } from 'modules/charts/hooks/use-fetch-chart-data.hook';
import { usePerformanceChartStyles } from 'modules/charts/styles';
import { FilledCircleComponent } from 'modules/common/components/filled-circle/filled-circle.component';
import { TableNoData } from 'modules/common/components/table-no-data/table-no-data.component';
import { useTranslation } from 'modules/common/hooks';
import { roundDecimals } from 'modules/common/utils/round-decimals.util';
import { FunctionComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import { chartBarsThemes } from 'views/contract-details/partials/contract-details-status/chart-bars-themes.partial';
import { ProgressLineStatusPartial } from 'views/contract-details/partials/contract-details-status/progress-line-status.partial';

interface IProps {
  chartData: ChartDataInterface;
}

interface PerformanceChartProp {
  selectedYearPerformanceChart: ChartDataInterface;
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, ChartjsTooltip, Legend, ChartDataLabels);

const ChartWrapper = (props: PerformanceChartProp) => {
  const { t } = useTranslation();
  const classes = usePerformanceChartStyles();
  const { lightGreen, darkGreen, redPink } = chartBarsThemes;
  const { selectedYearPerformanceChart } = props;
  const { yAxisPerformanceChart, xAxis } = selectedYearPerformanceChart;
  const { isContractedVolume, targetQuantities, usedQuantities, usedQuantitiesOutsideContract, totalContractedVolume } =
    yAxisPerformanceChart;
  const simplifyNumber = (number: number): string | number => {
    // If the number is greater than or equal to 1000

    if (Math.abs(number) >= 10000) {
      // Divide the number by 1000 and round it to 1 decimal place
      const simplifiedNumber: number = parseFloat((Math.sign(number) * (Math.abs(number) / 1000)).toFixed(1));
      // Append 'k' to the simplified number
      return simplifiedNumber + 'k';
    } else {
      // If the number is less than 1000, return the number as is
      return number;
    }
  };

  const label = (context) => {
    if (context.datasetIndex === 0) {
      const labelX = context.formattedValue || '';
      return `Contracted Volume: ${labelX} ( MT )`;
    }

    if (context.datasetIndex === 1) {
      const labelX = context.formattedValue || '';
      return `Ordered Volume: ${labelX} ( MT )`;
    }
    if (context.datasetIndex === 2) {
      const labelX = context.formattedValue || '';
      return `Ordered Volume out of contract period: ${labelX} ( MT )`;
    }
    return '';
  };

  const getTransparentBarValues = () =>
    targetQuantities.map((_x, i) =>
      max([targetQuantities[i], usedQuantities[i], usedQuantitiesOutsideContract[i]]),
    );

  const calculateDataLabelOffset = (arr: number[], isContractedOffset) => {
    //getting the largest value in all datapoints.
    const largestValue: number = Math.max(...targetQuantities, ...usedQuantities, ...usedQuantitiesOutsideContract);
    return arr.map((x) => {
      //Applying changes to the offset if the value is smaller than 20% of the largest value.
      if (isContractedOffset) {
        return x < 0.2 * largestValue ? 20 : 0;
      } else {
        return x < 0.2 * largestValue ? 0 : -35;
      }
    });
  };

  const calculateDataLabelColor = (arr: number[], isOutBound: boolean) => {
    //getting the largest value in all datapoints.
    const largestValue: number = Math.max(...targetQuantities, ...usedQuantities, ...usedQuantitiesOutsideContract);
    return arr.map((x) => {
      //Applying changes to the colors if the value is smaller than 20% of the largest value.
      if (isOutBound) {
        return x < 0.2 * largestValue ? '#f06e6e' : 'white';
      } else {
        return x < 0.2 * largestValue ? '#68c0b5' : 'white';
      }
    });
  };

  const options = {
    layout: {
      padding: {
        top: 50,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        // filter(context) {
        //   return context?.dataset?.id !== 'transparent-bar';
        // },
        // callbacks: {
        //   label,
        // },
      },
      datalabels: {
        anchor: 'end',
        align: 'top',
        clamp: true,
        rotation: 0,
        padding: 12,
        labels: {
          clamp: true,
        },
        font: {
          size: '12',
        },
        display(context) {
          return (
            context?.dataset?.id === 'transparent-bar' ||
            context?.dataset?.id === 'used-quantity-bar' ||
            context?.dataset?.id === 'out-bound'
          );
        },
        formatter(value, context) {
          const displayValue = context?.dataset?.labelData[context?.dataIndex];
          if (displayValue) {
            if (
              (isContractedVolume && context?.dataset?.id === 'transparent-bar') ||
              context?.dataset?.id === 'used-quantity-bar' ||
              context?.dataset?.id === 'out-bound'
            ) {
              return simplifyNumber(displayValue.toFixed(1));
            } else {
              return '';
            }
          } else {
            return '';
          }
        },
      },
    },
    barThickness: 40,
    borderRadius: 0,
    scales: {
      x: {
        display: true,
        stacked: true,
        grid: {
          display: false,
          drawBorder: true,
        },
        ticks: {
          beginAtZero: true,
        },
      },
      y: {
        display: true,
        beginAtZero: true,
        grid: {
          color: '#e5eff1',
          display: true,
          drawBorder: false,
          borderDash: [5, 5],
        },
        ticks: {
          beginAtZero: true,
        },
      },
    },
  };

  const chartData = {
    labels: xAxis,
    datasets: [
      {
        label: 'Target average quantity',
        data: targetQuantities,
        backgroundColor: lightGreen,
        order: 1,
        borderSkipped: false,
        borderRadius: {
          topLeft: 2,
          topRight: 2,
          bottomLeft: 0,
          bottomRight: 0
        },
        barPercentage: 0.7,
        categoryPercentage: 1,
        inflateAmount: 0,
      },
      {
        label: t('contract-details.status.used-quantity'),
        id: 'used-quantity-bar',
        labelData: usedQuantities,
        boderRadius: 0,
        data: usedQuantities,
        backgroundColor: darkGreen,
        order: 0,
        borderSkipped: false,
        barPercentage: 0.7,
        categoryPercentage: 1,
        datalabels: {
          offset: calculateDataLabelOffset(usedQuantities, false), // Adjust the offset for this dataset
          color: calculateDataLabelColor(usedQuantities, false),
          font: {
            weight: 'bold', // Make the font bold
          },
        },
        inflateAmount: 0,
      },
      {
        label: t('Out of bound'),
        id: 'out-bound',
        data: usedQuantitiesOutsideContract,
        labelData: usedQuantitiesOutsideContract,
        backgroundColor: redPink,
        order: 0,
        borderSkipped: false,
        borderRadius: {
          topLeft: 2,
          topRight: 2,
          bottomLeft: 0,
          bottomRight: 0
        },
        datalabels: {
          offset: calculateDataLabelOffset(usedQuantitiesOutsideContract, false), // Adjust the offset for this dataset
          color: calculateDataLabelColor(usedQuantitiesOutsideContract, true),
          font: {
            weight: 'bold', // Make the font bold
          },
        },
        barPercentage: 0.7,
        categoryPercentage: 1,
        inflateAmount: 0,
      },
      {
        id: 'transparent-bar',
        data: getTransparentBarValues(),
        boderRadius: 0,
        labelData: targetQuantities,
        backgroundColor: 'transparent',
        order: 0,
        //calculateContractedVolOffset
        datalabels: {
          offset: calculateDataLabelOffset(targetQuantities, true),
          color: '#626262',
          font: {
            weight: 'bold', // Make the font bold
          },
        },
        borderSkipped: false,
        barPercentage: 0.7,
        categoryPercentage: 1,
        pointRadius: 0,
        pointHitRadius: 0,
        inflateAmount: 0,
      },
    ],
  };

  return (
    <div
      className={classes.barChart}
      style={{ height: isContractedVolume && !Boolean(totalContractedVolume) ? '400px' : '300px' }}
    >
      <div className={classes.barUnit}>MT</div>
      <Bar options={options as unknown} data={chartData as any} />
    </div>
  );
};

export const PerformanceChart: FunctionComponent<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  const { chartData } = props;
  const classes = usePerformanceChartStyles();

  if (!chartData) {
    return (
      <Box display={'flex'} width={'100%'} justifyContent={'center'}>
        <TableNoData />
      </Box>
    );
  }

  const {
    yAxisPerformanceChart: {
      usedQuantities,
      targetQuantities,
      usedQuantitiesOutsideContract,
      isContractedVolume,
      totalContractedVolume,
      usaagePercentage,
    },
    year,
  } = chartData;

  const sumArray = (arr) => arr.reduce((acc, curr) => acc + curr, 0);

  const totalUsedVolume = sumArray(usedQuantities);
  const totalOutOfContractVolume = sumArray(usedQuantitiesOutsideContract);
  const totalOrderedVolume = (totalUsedVolume + totalOutOfContractVolume).toFixed(2);

  const getTooltipTitle = (contractDetails) =>
    `${t('contract-details.status.total-used-quantity')} / Target average quantity: ${roundDecimals(
      contractDetails?.status.totalUsedQuantity,
    )} / ${roundDecimals(contractDetails?.status.totalQuantity)}`;

  const chartLegendValues = [
    { fillColor: '#E3F4F3', title: 'Contracted Volume' },
    { fillColor: '#68C0B5', title: 'Ordered Volume' },
    ...(totalOutOfContractVolume ? [{ fillColor: '#F06E6E', title: 'Ordered Volume out of Contract Period' }] : []),
  ];

  return (
    <div>
      <Box width={'100%'}>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '133.4%',
          }}
          color="black"
          variant='semiBold'
          component={'div'}
          padding={'18px'}
        >
          {Boolean(totalContractedVolume)
            ? 'Contract Performance'
            : 'Ordered volume per month (based on planned delivery date)'}
        </Typography>
        <Divider />
      </Box>
      {Boolean(totalContractedVolume) && (
        <>
          <Stack padding={'8px 18px'} flexDirection={'row'} gap="24px">
            {chartLegendValues.map((item, index) => (
              <Stack flexDirection={'revert'} gap={'5px'} alignItems={'center'} key={index}>
                <FilledCircleComponent circleProps={{ fill: item.fillColor }} />
                <Typography color="black"
                  sx={{
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '166%',
                    letterSpacing: '0.17px',
                  }} component={'div'}>
                  {item.title}
                </Typography>
              </Stack>
            ))}
          </Stack>
          <Divider />
        </>
      )
      }
      <Container fixed={false} maxWidth="xl" sx={{ padding: '18px !important' }}>
        <Stack columnGap={'5px'}>
          {Boolean(totalContractedVolume) && !isContractedVolume && targetQuantities.find((x) => x !== 0) && (
            <div>
              <span className={classes.averageQuantityDiv}>
                Average contracted volume per month: <span style={{ fontWeight: 600 }}>{targetQuantities.find((x) => x !== 0)} MT</span>
              </span>
            </div>
          )}

          <Box>
            <ChartWrapper selectedYearPerformanceChart={chartData} />
          </Box>

          {Boolean(totalContractedVolume) ? (
            <Box marginY={'5px'}>
              <Stack gap={'12px'}>
                <Typography component={'div'} fontSize={'15px'} lineHeight={'160%'} fontWeight={600}>
                  Total (Whole Contract Period)
                </Typography>

                <StyledProgressContainer>
                  {/* <Tooltip title={
                      <>Change me </>
                      // getTooltipTitle(contractDetailsdata)
                  }> */}
                  <ProgressLineStatusPartial
                    backgroundColor="#e3f4f3"
                    visualParts={[
                      {
                        percentage: `${usaagePercentage.beforeContract}%`,
                        color: chartBarsThemes.redPink,
                      },
                      {
                        percentage: `${usaagePercentage.withinContract}%`,
                        color: chartBarsThemes.darkGreen,
                      },
                      {
                        percentage: `${usaagePercentage.afterContract}%`,
                        color: chartBarsThemes.redPink,
                      },
                    ]}
                  />
                  {/* </Tooltip> */}
                </StyledProgressContainer>
                <Typography component={'div'} alignSelf={'flex-end'} fontSize={'14px'} lineHeight={'166%'} variant="body2">
                  Total Ordered Volume ({year})/ Total Contracted Volume:{' '}
                  <Typography component={'span'} color={'black'} fontSize={'14px'} lineHeight={'160%'} fontWeight={600}>
                    {totalOrderedVolume} / {totalContractedVolume}
                  </Typography>
                </Typography>
              </Stack>
            </Box>
          ) : (
            <Box marginY={'10px'}>
              <span className={classes.orderQuantityDiv}>Total Ordered Volume ({year}):</span>
              <b>{totalOrderedVolume}</b>
            </Box>
          )}
        </Stack>
      </Container>
    </div >
  );
};

const StyledProgressContainer = styled(MuiBox)(() => ({
  flex: 1,
  minWidth: '250px',
}));
