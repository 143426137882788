import { BreadcrumbsItemInterface } from 'modules/breadcrumbs/interfaces';
import { useRouter, useTranslation } from 'modules/common/hooks';
import { useMemo } from 'react';
import routes from 'routes';

export const useContractDetailsBreadcrumbs = (): BreadcrumbsItemInterface[] => {
  const { t } = useTranslation();
  const router = useRouter();
  const { id, type } = router.query as { id: string; type: string };

  const items = useMemo(
    () => [
      {
        label: t('title_contracts'),
        href: { route: routes.contracts },
      },
      {
        label: `${t('title_contract')} ${id}`,
        href: { route: routes.contractDetails, query: { id, type: type?.toUpperCase() } },
      },
    ],
    [],
  );

  return items;
};
