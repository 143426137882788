/* eslint-disable @roq/no-invalid-resource-location */
import { Box, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ArrowCounterClockwise, PencilSimple, Trash } from '@phosphor-icons/react';
import { LoadingSkeleton, RoqLink, Table, TableColumnInterface } from 'modules/common/components';
import { EllipseTextComponent } from 'modules/common/components/ellipse-text';
import { TableNoData } from 'modules/common/components/table-no-data/table-no-data.component';
import { ToolTipCommon } from 'modules/common/components/tool-tip-common/tool-tip-common.component';
import { useRouter, useTranslation } from 'modules/common/hooks';
import { roundDecimals } from 'modules/common/utils';
import { ContractTypeEnum } from 'modules/contract-details/enums';
import { OrderInterface } from 'modules/order-details/interfaces';
import { OrderStatusChipComponent } from 'modules/orders/components/order-status-chip/order-status-chip.component';
import { UseOrderTableInterface, UseOrderTableOptionsInterface } from 'modules/orders/hooks';
import { ThemeEnum } from 'modules/theme/enums';
import React, { ReactElement, useEffect, useMemo } from 'react';
import routes from 'routes';
import { OrderTypeEnum } from 'views/orders/enums';
import { useOrdersCardStyles } from 'views/overview/partials/orders-card/orders-card.styles';

interface OrdersCardTablePartialInterface {
  useFetchHook: (options?: UseOrderTableOptionsInterface) => UseOrderTableInterface;
  hookOptions?: UseOrderTableOptionsInterface;
  filterColumns?: (columns: TableColumnInterface<OrderInterface>[]) => TableColumnInterface<OrderInterface>[]
}

export const OrdersCardTablePartial = (props: OrdersCardTablePartialInterface): ReactElement => {
  const { useFetchHook, hookOptions, filterColumns } = props;

  const classes = useOrdersCardStyles();
  const {
    data,
    order,
    totalCount,
    pageSize,
    isLoading,
    pageNumber,
    handlePageChange,
    handleOrderChange,
    handleFilterChange,
    reload,
  } = useFetchHook(hookOptions);

  useEffect(() => reload(), []);

  const { t } = useTranslation();
  const theme = useTheme();
  const router = useRouter();
  const { push } = router;

  useEffect(() => {
    handleFilterChange(props.hookOptions.filter);
  }, [JSON.stringify(hookOptions.filter)]);

  const tableColumns = [
    {
      title: 'Ship to',
      field: 'orderShippingAddress',
      render: (rowData: OrderInterface) => <EllipseTextComponent text={rowData.orderShippingAddress} />,
    },
    {
      title: 'Customer PO number',
      field: 'customerOrderReference',
      render: (rowData: OrderInterface) => <EllipseTextComponent text={rowData.customerOrderReference} />,
    },
    {
      title: t('orders.table.fields.id'),
      field: 'id',
      render: (rowData: OrderInterface) => (
        <RoqLink
          className={classes.links}
          onClick={() => {
            void push(`/${routes.orders}/${rowData.id}`);
          }}
        >
          {rowData.id}
        </RoqLink>
      ),
    },
    {
      title: t('orders.table.fields.product'),
      field: 'firstProductName',
      render: (rowData: OrderInterface) => <EllipseTextComponent text={rowData.firstProductName} />,
    },
    {
      title: t('orders.table.fields.order-type'),
      field: 'orderType',
      sorting: false,
      render: (rowData: OrderInterface) => <EllipseTextComponent text={(rowData?.orderType === OrderTypeEnum.CALL_OFF ? 'Call offs' : 'Spot')} />,
    },
    {
      title: t('orders.table.fields.helm-contract-id'),
      field: 'contractId',
      render: (rowData: OrderInterface) => (
        <>
          {rowData.orderType === OrderTypeEnum.CALL_OFF ? (
            <RoqLink
              className={classes.links}
              onClick={() => {
                void push(`/${routes.contracts}/${rowData.contractId}/${ContractTypeEnum.FRAME}`);
              }}
            >
              {rowData.contractId}
            </RoqLink>
          ) : (
            'N/A'
          )}
        </>
      ),
    },
    {
      title: 'Ordered Volume',
      field: 'orderQuantity',
      render: (rowData: OrderInterface) => <>{roundDecimals(rowData?.orderQuantity)}</>,
    },
    {
      title: t('Deliveries Planned (until today)'),
      field: 'deliveryCount',
      render: (rowData: OrderInterface) => <EllipseTextComponent text={rowData.deliveryCount.toString()} />,
    },
    {
      title: t('orders.table.fields.status'),
      field: 'status',
      render: (rowData: OrderInterface) => <OrderStatusChipComponent status={rowData.status} fullWidth />,
      sorting: false,
    },
    {
      title: t('actions'),
      render: (rowData: OrderInterface) => (
        <>
          <Box display={'flex'} gap={'6px'}>
            <ToolTipCommon title={'Edit'}>
              <IconButton>
                <PencilSimple />
              </IconButton>
            </ToolTipCommon>
            <ToolTipCommon title={'Refresh'}>
              <IconButton>
                <ArrowCounterClockwise />
              </IconButton>
            </ToolTipCommon>

            <ToolTipCommon title={'Delete'}>
              <IconButton>
                <Trash />
              </IconButton>
            </ToolTipCommon>
          </Box>
        </>
      ),
      sorting: false,
    },
  ];
  const tableData = useMemo(() => data?.map((x) => ({ ...x })), [data]);
  return (
    <>
      {isLoading && <LoadingSkeleton />}

      {!isLoading && (
        <Table
          page={pageNumber}
          onPageChange={handlePageChange}
          onOrderChange={handleOrderChange}
          order={order}
          totalCount={totalCount}
          columns={filterColumns ? filterColumns(tableColumns) : tableColumns}
          isLoading={isLoading}
          noDataComponent={TableNoData}
          data={tableData}
          onRowClick={(_, rowData) => {
            void push(`/${routes.orders}/${rowData.id}`);
          }}
          options={{
            paginationType: 'stepped',
            pageSize,
            showTitle: false,
            search: false,
            sorting: true,
            toolbar: false,
            draggable: false,
            thirdSortClick: false,
            rowStyle: {
              color: theme.palette.mode === ThemeEnum.LIGHT ? theme.palette.common.black : theme.palette.common.white,
            },
            paging: false,
          }}
        />
      )}
    </>
  );
};
