import * as React from "react";
export const FilledCircleComponent = (props: {
  elementProps?: React.SVGProps<SVGSVGElement>,
  circleProps?: React.SVGProps<SVGCircleElement>
}): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={13}
    viewBox="0 0 12 13"
    fill="none"
    {...props}
  >
    <circle cx={6} cy={6.5} r={6} fill="#E3F4F3" {...props.circleProps} />
  </svg>
);