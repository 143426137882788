// eslint-disable-next-line @roq/no-invalid-style-resource
import { roqMakeStyles } from 'modules/common/utils';

export const useProgressLineStatusStyles = roqMakeStyles(() => ({
  progressVisualFull: {
    display: 'flex',
    height: '8px', // Changed height to '8px'
  },
  progressVisualPart: {
    transition: 'width 2s',
    backgroundColor: '#3890DB', // Changed background color
  },
  progressLabel: {
    fontfamily:
      '"Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif',
  },
}));
