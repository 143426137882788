import { Theme } from "@mui/material";
import { roqMakeStyles } from "modules/common/utils";
import { ThemeEnum } from "modules/theme/enums";

export const useOrdersCardStyles = roqMakeStyles((theme: Theme) => ({
  topSection: {
    padding: "1.5rem 2rem",
    borderBottom:
      theme.palette.mode === ThemeEnum.LIGHT
        ? "1px solid #EEEEEE"
        : "1px solid #293445",
  },
  textCommon: {
    color: theme.palette.common.black,
  },
  links: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  title: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  buttonSection: {
    display: "flex",
    alignItems: "center",
  },
  filterButton: {
    display: "flex",
    justifyContent: "end",
  },
  filterHeading: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  filterBy: {
    marginRight: "5px",
  },
}));
