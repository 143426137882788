import { roqMakeStyles } from 'modules/common/utils';

export const useNoDataStyles = roqMakeStyles(() => ({
  container: (props: { [name: string]: string }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    ...(props?.height && { height: props.height })
  })
}));
