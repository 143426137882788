import { OrderEnum } from 'modules/common/enums';
import { useAsyncOperation } from 'modules/common/hooks';
import { useCurrentBusinessPartnerInternal } from 'modules/current-business-partner-selector/hooks';
import { setOrderTableState } from 'modules/filter-by/filter-by.slice';
import { useFilterBy } from 'modules/filter-by/hooks';
import { OrderInterface } from 'modules/order-details/interfaces';
import { OrderSortEnum, OrderStatusEnum } from 'modules/orders/enums';
import { useFetchOrders } from 'modules/orders/hooks';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { OrderTypeEnum } from 'views/orders/enums';

export interface OrderTableFilterInterface {
  kunnr?: string;
  productIds?: string[];
  universalIds?: string[];
  orderTypes?: OrderTypeEnum[];
  status?: OrderStatusEnum[];
  shipToAddressIds?: string[];
}
interface OrderTableStateInterface {
  pageNumber: number;
  pageSize: number;
  order?: {
    sort: OrderSortEnum;
    order: OrderEnum;
  };
  filter?: OrderTableFilterInterface;
}

export interface UseOrderTableInterface extends OrderTableStateInterface {
  data: OrderInterface[];
  totalCount: number;
  isLoading: boolean;
  handlePageChange: (newPage: number, pageSize: number) => void;
  handleFilterChange: (newFilter: OrderTableFilterInterface) => void;
  handlePageRowsCountChange?: (rowsCount: number) => void;
  handleOrderChange: (sort: OrderSortEnum, order: OrderEnum) => void;
  handleSearchByUniversalIds?: (universalIds: string[]) => void;
  reload?: () => void;
}

export interface UseOrderTableOptionsInterface {
  pageSize?: number;
  filter?: OrderTableFilterInterface;
}

export const useOrderTable = (options: UseOrderTableOptionsInterface): UseOrderTableInterface => {
  const { fetchOrders } = useFetchOrders();
  const { orderState } = useFilterBy();
  const dispatch = useDispatch();
  const { products, orderTypes, statuses, shipToAddresses, tableState: orderTableState, searchId } = orderState;

  const [tableState, setTableState] = useState<OrderTableStateInterface>({
    pageNumber: 0,
    pageSize: orderTableState?.rowPerPage || 20,
    order: { sort: OrderSortEnum.ID, order: OrderEnum.DESC },
    filter: {
      ...options?.filter,
    },
  });

  const { currentBusinessPartnerInternal } = useCurrentBusinessPartnerInternal();

  const { isLoading, status, initiateOperation } = useAsyncOperation({
    callback: fetchOrders,
  });

  const totalCount = status?.result?.totalCount;
  const data = status?.result?.data;

  const { pageNumber, pageSize, order, filter } = tableState;

  useEffect(() => {
    const appliedFilter: OrderTableFilterInterface = {};

    // For customer users, we always need the kunnr to be passed
    // For admin users, the kunnr is applied only if we are not searching by contractId

    if (currentBusinessPartnerInternal?.kunnr) {
      appliedFilter.kunnr = currentBusinessPartnerInternal?.kunnr;

      if (products?.length) {
        appliedFilter.productIds = products.map((x) => x.id);
      }
      if (orderTypes?.length >= 1) {
        appliedFilter.orderTypes = orderTypes.map((t) => t.value);
      }
      if (statuses?.length) {
        appliedFilter.status = statuses.map(item => item.value);
      }
      if (shipToAddresses?.length) {
        appliedFilter.shipToAddressIds = shipToAddresses.map((a) => a.id);
      }
      if (searchId?.length) {
        appliedFilter.universalIds = [searchId];
      }

      void initiateOperation({
        offset: pageNumber * pageSize,
        limit: pageSize,
        order,
        filter: { ...appliedFilter, ...filter },
      });
    }
  }, [
    statuses,
    products,
    orderTypes,
    shipToAddresses,
    currentBusinessPartnerInternal,
    pageNumber,
    pageSize,
    order,
    searchId,
    JSON.stringify(filter),
  ]);

  const reload = useCallback(() => {
    setTableState((ts) => ({
      ...ts,
      pageNumber: 0,
      pageSize: options?.pageSize || 20,
      order: { sort: OrderSortEnum.ID, order: OrderEnum.DESC },
      filter: {},
    }));
  }, [setTableState]);

  const handleFilterChange = useCallback(
    (newFilter: OrderTableFilterInterface) => {
      setTableState((ts) => ({
        ...ts,
        pageNumber: 0,
        filter: newFilter,
      }));
    },
    [setTableState],
  );

  const handleOrderChange = useCallback(
    (sort: OrderSortEnum, orderDirection: OrderEnum) => {
      setTableState((ts) => ({
        ...ts,
        order: {
          sort,
          order: orderDirection,
        },
      }));
    },
    [setTableState],
  );

  const handlePageRowsCountChange = useCallback(
    (newPageSize: number) => {
      setTableState((ts) => ({
        ...ts,
        pageSize: newPageSize,
      }));
    },
    [setTableState],
  );

  const handlePageChange = useCallback(
    (newPageNumber: number, newPageSize: number) => {
      dispatch(setOrderTableState({ rowPerPage: newPageSize }));
      setTableState((ts) => ({
        ...ts,
        pageNumber: newPageNumber,
        pageSize: newPageSize,
      }));
    },
    [setTableState, dispatch],
  );

  return {
    ...tableState,
    handlePageChange,
    handlePageRowsCountChange,
    handleOrderChange,
    handleFilterChange,
    reload,
    isLoading,
    data,
    totalCount,
  };
};
