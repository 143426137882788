import { Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { getYear, parseISO } from 'date-fns';
import { PerformanceChart, PriceChart } from 'modules/charts/components';
import { useFetchChartData } from 'modules/charts/hooks';
import { ChartDataInterface } from 'modules/charts/hooks/use-fetch-chart-data.hook';
import { CommonPaper } from 'modules/common/components/common-paper/common-paper.component';
import { useAsyncOperation, useTranslation } from 'modules/common/hooks';
import { FunctionComponent, useEffect, useState } from 'react';

interface ChartProps {
  contractId?: string;
  productId?: string;
  kunnr?: string;
  shipToAddress?: string[];
  verticalDivider?: boolean;
  componentDirection?: 'row' | 'col';
}
export const Charts: FunctionComponent<ChartProps> = (props: ChartProps) => {
  const { contractId, productId, kunnr, shipToAddress, componentDirection = 'col', verticalDivider = false } = props;
  const gridItemsSize = componentDirection === 'row' ? 12 : 6;
  const { fetchChartData } = useFetchChartData();
  const { status, initiateOperation, isLoading } = useAsyncOperation({
    callback: fetchChartData,
  });
  const { t } = useTranslation();
  const [selectedYear, setSelectedYear] = useState(2023);
  const [startYear, setStartYear] = useState(2023);
  const [endYear, setEndYear] = useState(2023);
  const initialState = {
    year: 2023,
    xAxis: [],
    yAxisPriceChart: [],
    yAxisPerformanceChart: {
      targetQuantities: [],
      usedQuantities: [],
      usedQuantitiesOutsideContract: [],
      isContractedVolume: false,
      totalContractedVolume: 0,
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString(),
      usaagePercentage: {
        beforeContract: 0,
        withinContract: 0,
        afterContract: 0,
      },
    },
  };
  const [selectedChartData, setSelectedChartData] = useState<ChartDataInterface>(initialState);

  const TitleCustomComponent = () => (
    <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'} justifyItems={'center'} alignItems={'center'}>
      <Grid container paddingY={'2px'} columnGap={1}>
        <Grid item>
          <Typography variant="h3" sx={{
            fontSize: '20px !important',
            fontWeight: 700,
            lineHeight: '133.4%'
          }} color="black">
            {t('contract-details.status.title')}
          </Typography>
        </Grid>
      </Grid>
      {Boolean(status?.result?.chartData?.length) && (
        <Stack flexDirection={'row'} alignItems={'center'}>
          <IconButton
            aria-label="Previous Page"
            disabled={selectedYear - 1 < startYear}
            onClick={() => handleSelectedYear(selectedYear - 1)}
            color="inherit"
          >
            <CaretLeft size={20} />
          </IconButton>
          <Typography fontSize={'14'} color="black" component={'div'}>
            {selectedYear}
          </Typography>
          <IconButton
            aria-label="Next Page"
            onClick={() => handleSelectedYear(selectedYear + 1)}
            disabled={selectedYear + 1 > endYear}
            color="inherit"
          >
            <CaretRight size={20} />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );

  const updateChart = (chartData, year) => {
    setSelectedYear(year);
    const selectedChart = chartData.find((x) => x.year === year);

    if (selectedChart) {
      const { yAxisPerformanceChart } = selectedChart;
      const { startDate, endDate } = yAxisPerformanceChart;
      setStartYear(getYear(parseISO(startDate)));
      setEndYear(getYear(parseISO(endDate)));
      setSelectedChartData(selectedChart);
    } else {
      setSelectedChartData({ ...initialState, year });
    }
  };

  const handleSelectedYear = (year: number) => {
    updateChart(status?.result?.chartData, year);
  };

  useEffect(() => {
    const chartData = status?.result?.chartData;
    if (chartData) {
      const year = chartData[0]?.year ? chartData[0]?.year : selectedYear;
      updateChart(chartData, year);
    }
  }, [status?.result?.chartData]);

  useEffect(() => {
    const filter: ChartProps = {};
    if (contractId) {
      filter.contractId = contractId;
    }
    if (productId) {
      filter.productId = productId;
    }
    if (kunnr) {
      filter.kunnr = kunnr;
    }
    if (shipToAddress) {
      filter.shipToAddress = shipToAddress;
    }
    void initiateOperation(filter);
  }, [contractId, productId, kunnr, shipToAddress]);

  return (
    <Grid item xs={12}>
      {!isLoading && (
        <CommonPaper>
          <Grid container xs={12} padding={'18px'}>
            <TitleCustomComponent />
          </Grid>
          <Divider variant='fullWidth' sx={{ minWidth: '100%' }} />
          <Grid item xs={12} sm={gridItemsSize} sx={{ position: 'relative' }} md={gridItemsSize} lg={gridItemsSize} >
            <PerformanceChart chartData={selectedChartData} />
          {verticalDivider ?
            <Divider orientation='vertical' sx={{ minHeight: '50% !important', position: 'absolute', left: '100%', top: '0' }} />
            :
            null
          }
          </Grid>
          <Grid item xs={12} sm={gridItemsSize} md={gridItemsSize} lg={gridItemsSize}>
            {!verticalDivider ?
              <Divider variant='fullWidth' />
              :
              null
            }
            <PriceChart chartData={selectedChartData} />
          </Grid>
        </CommonPaper>
      )}
    </Grid>
  );
};
