import { useAsyncOperation } from 'modules/common/hooks';
import { useFetchInvoicesStatusCount } from 'modules/invoices/hooks/use-fetch-invoices-status-count.hook';
import { InvoicesTableFilterInterface } from 'modules/invoices/hooks/use-invoices-document.hook';
import { InvoicesFetchStatusCountArgsInterface } from 'modules/invoices/interfaces/invoices-fetch-status-count-args.interface';
import { InvoicesStatusCountInterface } from 'modules/invoices/interfaces/invoices-status-count.interface';
import { useEffect, useState } from 'react';

export const useInvoicesStatusCount = (
  options: InvoicesFetchStatusCountArgsInterface,
): { data: InvoicesStatusCountInterface[]; total: number; isLoading: boolean } => {
  const { fetchInvoicesStatusCount } = useFetchInvoicesStatusCount();
  const {
    isLoading,
    initiateOperation,
    status: dataStatus,
  } = useAsyncOperation({ callback: fetchInvoicesStatusCount });
  const [filterState, setFilterState] = useState<InvoicesFetchStatusCountArgsInterface>({
    ...options,
  });

  const data = dataStatus?.result?.data || [];
  const total = data?.reduce((acc, statusItem) => acc + statusItem?.totalCount, 0);
  const { kunnr, productIds, shipToAddressIds, deliveryId, orderId, orderType, searchText, documentTypes } = filterState?.filter;

  useEffect(() => {
    if (options?.filter) {
      setFilterState({
        ...filterState,
        ...options,
      });
    }
  }, [options?.filter]);


  useEffect(() => {
    if (filterState?.filter?.kunnr) {
      const appliedFilter: InvoicesTableFilterInterface = {};
      if (productIds?.length) {
        appliedFilter.productIds = productIds
      }
      if (shipToAddressIds?.length) {
        appliedFilter.shipToAddressIds = shipToAddressIds
      }
      if (documentTypes?.length) {
        appliedFilter.documentTypes = documentTypes?.map((documentType) =>
        ({
          prefix: documentType.prefix,
          typeId: documentType.typeId
        }))
      }

      void initiateOperation({
        filter: {
          ...filterState?.filter,
          ...appliedFilter,
          kunnr,
          ...(orderId ? { orderId } : {}),
          ...(deliveryId ? { deliveryId } : {}),
          ...(orderType?.length ? { documentOrderType: orderType[0]?.value } : {}),
          ...(searchText ? { searchText } : {})
        },
      });
    }
  }, [filterState?.filter]);

  return {
    data,
    total,
    isLoading,
  };
};
