import snakeCase from 'lodash/snakeCase'
import { TableStatusComponent } from 'modules/common/components/table-status/table-status.component';
import { OrderStatusEnum } from 'modules/orders/enums'
import { FunctionComponent } from 'react'

const getChipColor = (status: OrderStatusEnum) => {
  switch (snakeCase(status).toUpperCase()) {
    case OrderStatusEnum.RECEIVED:
      return 'gray'
    case OrderStatusEnum.CONFIRMED:
      return 'cyan'
    case OrderStatusEnum.IN_PROGRESS:
      return 'blue'
    case OrderStatusEnum.COMPLETED:
      return 'green'
    case OrderStatusEnum.INVOICED:
      return 'lightBlue'
  }
}
export const OrderStatusChipComponent: FunctionComponent<{ status: OrderStatusEnum; fullWidth?: boolean; stylingProps?: React.CSSProperties, spanStyling?: React.CSSProperties }> = ({ status, fullWidth, stylingProps, spanStyling }) => <TableStatusComponent fullWidth={fullWidth} stylingProps={stylingProps} spanStyling={spanStyling} label={status} color={getChipColor(status)} />
