import { gql } from '@apollo/client';
import { requestGql } from 'modules/common/utils/request-gql';
import { InvoicesFetchStatusCountInterface } from 'modules/invoices/interfaces/invoices-fetch-status-count.interface';
import { InvoicesFetchStatusCountArgsInterface } from 'modules/invoices/interfaces/invoices-fetch-status-count-args.interface';

export interface useFetchInvoicesStatusCountHookInterface {
  fetchInvoicesStatusCount: (query?: InvoicesFetchStatusCountArgsInterface) => Promise<InvoicesFetchStatusCountInterface>;
}

export const useFetchInvoicesStatusCount = (): useFetchInvoicesStatusCountHookInterface => {

  const fetchInvoicesStatusCount = async (args: InvoicesFetchStatusCountArgsInterface): Promise<InvoicesFetchStatusCountInterface> =>
    requestGql(
      {
        query: gql`
          query FetchInvoicesStatusCount(
            $filter: DocumentStatusFilterArgType!
          ) {
            documentsStatusCount(
              filter: $filter, 
            ) {
              data {
                status,
                totalCount
              }
            }
          }
        `,
        variables: args,
      },
      null,
      'documentsStatusCount',
    )


  return {
    fetchInvoicesStatusCount,
  };
};