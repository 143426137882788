import { gql } from '@apollo/client';
import { requestGql } from 'modules/common/utils/request-gql';

export interface ChartDataQueryInterface {
  contractId?: string;
  productId?: string;
  kunnr?: string;
  shipToAddress?: string[];
}

export interface ChartDataInterface {
  year?: number;
  xAxis?: string[];
  yAxisPriceChart?: {
    price?: number;
    repeated?: boolean;
    currency?: string;
  }[];
  yAxisPerformanceChart?: {
    targetQuantities?: number[];
    usedQuantities?: number[];
    usedQuantitiesOutsideContract?: number[];
    isContractedVolume?: boolean;
    totalContractedVolume?: number;
    startDate?: string;
    endDate?: string;
    usaagePercentage: {
      beforeContract: number;
      withinContract: number;
      afterContract: number;
    };
  };
}

export interface UseFetchChartDataHookInterface {
  fetchChartData: (query: ChartDataQueryInterface) => Promise<{ chartData: ChartDataInterface[] }>;
}

export const fetchChartData = (query: ChartDataQueryInterface): Promise<{ chartData: ChartDataInterface[] }> =>
  requestGql(
    {
      query: gql`
        query chartDataSet($args: ChartArgType!) {
          chartDataSet(args: $args) {
            chartData {
              year
              xAxis
              yAxisPriceChart {
                price
                currency
                repeated
              }
              yAxisPerformanceChart {
                targetQuantities
                usedQuantities
                usedQuantitiesOutsideContract
                isContractedVolume
                totalContractedVolume
                startDate
                endDate
                usaagePercentage {
                  withinContract
                  beforeContract
                  afterContract
                }
              }
            }
          }
        }
      `,
      variables: {
        args: {
          contractId: query.contractId,
          productId: query.productId,
          shipToAddress: query.shipToAddress,
          kunnr: query.kunnr,
        },
      },
    },
    null,
    'chartDataSet',
  );

export const useFetchChartData = (): UseFetchChartDataHookInterface => ({
  fetchChartData,
});
