import { Box, Button, Card, Divider, Grid, Stack, Typography } from '@mui/material';
import { AclProvider } from 'modules/acl';
import { TableColumnInterface } from 'modules/common/components';
import { useRouter, useTranslation } from 'modules/common/hooks';
import ShoppingCard from 'modules/common/icons/shopping-cart.svg';
import {
  resetOrderFilter,
  setOrderProductSearchByFilter,
  setOrderSearch,
  setOrderTypesFilter,
  setShipToAddressFilter,
} from 'modules/filter-by/filter-by.slice';
import { AddressOptionInterface } from 'modules/forms/components/address-search-multi/address-search-multi.component';
import { ProductAutocompleteOptionInterface } from 'modules/forms/components/product-autocomplete-search';
import { OptionInterface } from 'modules/forms/components/select/select.component';
import { OrderInterface } from 'modules/order-details/interfaces';
import { OrderTableFilterInterface, useOrderTable } from 'modules/orders/hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import routes from 'routes';
import { useOrdersCardStyles } from 'views/overview/partials/orders-card/orders-card.styles';
import { OrdersCardTablePartial } from 'views/overview/partials/orders-card/orders-card-table.partial';

export const OrdersCard = (props: {
  filterColumns?: (columns: TableColumnInterface<OrderInterface>[]) => TableColumnInterface<OrderInterface>[]
  filter?: OrderTableFilterInterface;
  selectedProduct?: ProductAutocompleteOptionInterface;
  selectedShipToAddresses?: AddressOptionInterface[];
  selectedOrderType?: OptionInterface;
  selectedContract?: string;
}): ReactElement => {
  const classes = useOrdersCardStyles();
  const router = useRouter();
  const { t } = useTranslation();
  const [filter, setFilter] = useState(props.filter);
  const dispatch = useDispatch();

  const handleApplyFilter = () => {
    dispatch(setOrderProductSearchByFilter({ product: [props?.selectedProduct] }));
    dispatch(setShipToAddressFilter(props?.selectedShipToAddresses));
    dispatch(setOrderTypesFilter([props?.selectedOrderType]));
    dispatch(setOrderSearch(props?.selectedContract));
  };

  useEffect(() => {
    setFilter(props.filter);
  }, [props.filter]);

  const CardHeader = ({ buttonCallback }) => (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.status}
      columnGap={1}
    >
      <Grid item xs="auto">
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <ShoppingCard />
          <Typography fontSize={'18px'} color="black" variant='semiBold'>
            {t('title_latest_orders')}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs="auto">
        <Button color="primary" onClick={buttonCallback} variant="text">
          <Typography color={'primary'}
            fontSize={14}
            variant='semiBold'
            fontWeight={800}
            letterSpacing={0.46}>
            {t('recent_orders.table.view-all')}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <>
      <AclProvider permission="orders.read">
        <Card>
          <Box sx={{ p: 2 }}>
            <CardHeader
              buttonCallback={() => {
                // reseting already applied filter for orders.
                dispatch(resetOrderFilter());
                void handleApplyFilter();
                void router.push({ route: routes.orders });
              }}
            />
          </Box>
          <Divider />
          <OrdersCardTablePartial filterColumns={props?.filterColumns} useFetchHook={useOrderTable} hookOptions={{ filter, pageSize: 3 }} />
        </Card>
      </AclProvider>
    </>
  );
};
